import { makeStyles } from 'tss-react/mui';

const buildClasses = makeStyles()(({ palette, spacing }) => {
  return {
    swiper: {
      display: 'flex !important',
      flexDirection: 'column',
      flex: '1',
      height: '100%',
      width: '100%',
    },
    swiperSlide: {
      height: 'fit-content !important',
      display: 'flex !important',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
    swiperWrapper: {
      flex: 1,
      position: 'relative',
      width: '100%',
      zIndex: 1,
      display: 'flex !important',
      transitionProperty: 'transform',
      transitionTimingFunction: 'var(--swiper-wrapper-transition-timing-function,initial)',
      boxSizing: 'content-box',
    },
    scrollableWrapper: {
      height: 'fit-content',
      display: 'flex !important',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
  };
});
export default buildClasses;
