import React, { useCallback, useEffect } from 'react';
import CustomerDataForm from 'components/Intake/Finalize/CustomerDataForm/CustomerDataForm';
import DeliveryTimeForm from 'components/Intake/Finalize/DeliveryTimeForm/DeliveryTimeForm';
import { CheckoutDetailsForm, emptyAddress } from 'components/Intake/Finalize/DeliveryAddressForm/AddressConst';
import DeliveryAddressForm, {
  DeliveryAddressErrors,
} from 'components/Intake/Finalize/DeliveryAddressForm/DeliveryAddressForm';
import { AvailableIntakeContents, PickUpTypesValues } from 'containers/Intake/IntakeConsts';
import { useFormContext } from 'react-hook-form';
import { setManualDeliveryAddress, getLocalizationDetails, getDeliveryAreaDetails } from 'stores/Intake';
import { Backdrop, Box, CircularProgress, useTheme } from '@mui/material';
import { ConnectivityStatusEnum } from 'services/ConnectivityStatusService';
import {
  checkCurrentCustomerCanPayOnAccount,
  clearSelectedCustomerAddress,
  getCustomerAddressesByPhone,
  selectCustomerAddress,
} from 'stores/Customer';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AddressSectionType } from 'stores/Config';
import _ from 'lodash';
import { mapFormDataToDeliveryAddress } from 'stores/Intake/IntakeStoreUtils';
import { recalculateBasket } from 'stores/Basket/basket.thunk-actions';
import { isAddressValid } from 'utils/intake/IntakeUtils';
import FinalizeAlerts from 'components/Intake/Finalize/FinalizeAlerts/FinalizeAlerts';
import CustomerAddressBook from '../AddressBook/CustomerAddressBook';

interface OrderRequiredInfoProps {
  onFormSubmit: (data: CheckoutDetailsForm) => void;
  reference: React.RefObject<HTMLFormElement>;
}

const OrderRequiredInfoForm: React.FC<OrderRequiredInfoProps> = ({ onFormSubmit, reference }) => {
  const { spacing } = useTheme();
  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<CheckoutDetailsForm>();

  const {
    activeDeliveryType,
    localizationDetails,
    selectedOrderCustomer,
    activeIntakeTab,
    selectedOrderDeliveryTime,
    onSiteSettings,
  } = useAppSelector(({ intake }) => intake);

  const { connectivityStatus, addressInput, useZipCodeAddressSuggestions, useDeliveryAddressCheck } =
    useAppSelector(({ config }) => config);
  const { customerAddresses, addressesLoading } = useAppSelector(({ customer }) => customer);
  const onAddressChangeDebounce = useCallback(_.debounce(onAddressChange, 500), [activeIntakeTab]);
  const dispatch = useAppDispatch();

  const addressBookMethod = customerAddresses.some((el) => el.isSelected);

  useEffect(() => {
    clearErrors();
  }, [activeDeliveryType]);

  useEffect(() => {
    if (!localizationDetails) return;
    const values = getValues();

    const newValues = {
      ...values,
      region: localizationDetails.region ?? values.region,
      city: localizationDetails.city ?? values.city,
      street: localizationDetails.street ?? values.street,
    };
    reset(newValues, { dirtyFields: true });
    dispatch(setManualDeliveryAddress(mapFormDataToDeliveryAddress(newValues)));

    if (isAddressValid(newValues, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(newValues));
      recalculate();
    }
  }, [localizationDetails]);

  useEffect(() => {
    const deliveryData = getValues();
    if (selectedOrderCustomer) {
      dispatch(checkCurrentCustomerCanPayOnAccount());
    }
    if (isAddressValid(deliveryData, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(deliveryData));
    }
  }, []);

  function recalculate() {
    if (onSiteSettings?.isToGoModeEnabled === true && activeIntakeTab !== AvailableIntakeContents.Customer) return;
    dispatch(recalculateBasket({}));
  }

  function submitForm(data: CheckoutDetailsForm): void {
    onFormSubmit(data);
  }

  function isAddressSearchingDisabled(): boolean {
    return (
      activeDeliveryType !== PickUpTypesValues.delivery || connectivityStatus !== ConnectivityStatusEnum.Connected
    );
  }
  function onPhoneNumberChanged(phoneNumber: string | null) {
    if (isAddressSearchingDisabled() || selectedOrderCustomer?.profile?.id) {
      return;
    }

    if (phoneNumber) {
      dispatch(getCustomerAddressesByPhone(phoneNumber));
    }
  }

  function changeToManualAddressInput(): void {
    dispatch(clearSelectedCustomerAddress());
  }

  function changeToAddressBook(): void {
    dispatch(selectCustomerAddress(customerAddresses[0]));
    dispatch(setManualDeliveryAddress());
    const values = getValues();
    reset({
      ...values,
      ...emptyAddress,
    });
  }

  function onAddressChange(sectionType: AddressSectionType) {
    const deliveryData = getValues();

    if (isAddressValid(deliveryData, addressInput)) {
      useDeliveryAddressCheck && dispatch(getDeliveryAreaDetails(deliveryData));
      recalculate();
    }

    const canApplySuggestion = !dirtyFields.city && !dirtyFields.street && !dirtyFields.region;

    if (useZipCodeAddressSuggestions) {
      switch (sectionType) {
        case AddressSectionType.Zipcode:
        case AddressSectionType.StreetNumber:
          if (deliveryData?.zipcode && deliveryData?.streetNumber && canApplySuggestion) {
            dispatch(
              getLocalizationDetails({
                zipCode: deliveryData.zipcode,
                streetNumber: deliveryData.streetNumber,
              }),
            );
          }
          break;
        default:
          break;
      }
    }
  }

  return (
    <form
      ref={reference}
      autoComplete="off"
      name="finalizeForm"
      onSubmit={handleSubmit(submitForm)}
      id="orderRequiredInfoForm"
    >
      <FinalizeAlerts />
      {(activeDeliveryType === PickUpTypesValues.pickUp || activeDeliveryType === PickUpTypesValues.delivery) && (
        <DeliveryTimeForm
          preselectedDateTime={selectedOrderDeliveryTime}
          isPickup={activeDeliveryType === PickUpTypesValues.pickUp}
        />
      )}
      <CustomerDataForm
        isPhoneRequired={activeDeliveryType !== PickUpTypesValues.takeAway}
        onPhoneNumberChanged={onPhoneNumberChanged}
      />
      {activeDeliveryType === PickUpTypesValues.delivery && (
        <>
          {addressBookMethod ? (
            <CustomerAddressBook
              addressBookEntries={customerAddresses}
              onAddressSelected={(address) => {
                dispatch(selectCustomerAddress(address));
              }}
              onInputMethodChanged={changeToManualAddressInput}
            />
          ) : (
            <>
              <Backdrop
                sx={{
                  zIndex: 1,
                  position: 'relative',
                  height: '320px',
                  mx: spacing(2),
                }}
                open={addressesLoading}
              >
                <CircularProgress />
              </Backdrop>
              <Box sx={{ marginTop: '-339px' }}>
                <DeliveryAddressForm
                  formRef={register}
                  errors={errors as DeliveryAddressErrors}
                  inputConfig={addressInput?.configurationElements}
                  onInputMethodChanged={
                    customerAddresses?.length > 0
                      ? () => {
                          if (customerAddresses.length === 0) return;
                          changeToAddressBook();
                        }
                      : undefined
                  }
                  onChangeFn={(sectionType) => {
                    dispatch(setManualDeliveryAddress(mapFormDataToDeliveryAddress(getValues())));
                    onAddressChangeDebounce(sectionType);
                  }}
                />
              </Box>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default OrderRequiredInfoForm;
