import { RootState } from 'stores';
import { LoyaltyTier, RewardCardItem } from 'typings/Loyalty';

export const getAllTiers = ({ loyalty }: RootState) => {
  return loyalty.rewardTabs.map((rewardTab) => {
    return {
      id: rewardTab.id,
      name: rewardTab.name,
      minimumValue: rewardTab.minimumValue,
    } as LoyaltyTier;
  });
};
