import React from 'react';
import { Box, Card, CardActionArea, Typography, useTheme } from '@mui/material';
import { Reward } from 'typings/Loyalty';
import LoyaltyRewardPrice from './LoyaltyRewardPrice';
import LoyaltyRewardImage from './LoyaltyRewardImage';

interface RewardCardProps {
  reward: Reward;
}

const RewardCard: React.FC<RewardCardProps> = ({ reward }) => {
  const { spacing, sizing, palette, breakpoints } = useTheme();

  return (
    <Card
      sx={{
        height: sizing.rewardCard.height,
        minWidth: '218px',
        position: 'relative',
        padding: `${spacing(1)} ${spacing(2)} ${spacing(1)} ${spacing(1)}`,
        boxShadow: 'none',
        width: `calc(${100 / sizing.rewardCard.lineCount.md}% - ${sizing.rewardCard.lineCount.md} * ${spacing(
          1.5,
        )})`,
        opacity: `${reward.locked ? '50' : '100'}%`,
        [breakpoints.down('md')]: {
          width: `calc(${100 / sizing.rewardCard.lineCount.xs}% - ${sizing.rewardCard.lineCount.xs} * ${spacing(
            2,
          )})`,
        },
        [breakpoints.up('xl')]: {
          width: `calc(${100 / sizing.rewardCard.lineCount.xl}% - ${sizing.rewardCard.lineCount.xl} * ${spacing(
            1,
          )})`,
        },
      }}
    >
      <CardActionArea
        disabled={reward.locked}
        sx={{
          height: sizing.full,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
        data-testid={`reward-card__button--${reward.name}`}
      >
        <LoyaltyRewardImage reward={reward} />
        <Box
          sx={{
            height: '100%',
            width: '65%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: palette.grey[800],
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              height: '90%',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {reward.name}
          </Typography>
          <LoyaltyRewardPrice
            priceInPoints={reward.priceInPoints}
            priceInMoney={reward.price && reward.price !== 0 ? reward.price : undefined}
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default RewardCard;
