import React from 'react';
import { ToggleButton, ToggleButtonGroup, Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PickUpTypesValues, ExtendedDeliveryType } from 'containers/Intake/IntakeConsts';
import { changeDeliveryType, setIsEatIn } from 'stores/Intake';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { DineInIcon } from 'assets/icons';
import { getExtendedAvailableDeliveryTypes } from 'stores/Intake/intake.selector';
import { getAllRewards } from 'stores/Loyalty/loyalty.thunk-actions';

interface ReceiptToggleGroupProps {
  isInDineInMode: boolean;
}

const ReceiptToggleGroup: React.FC<ReceiptToggleGroupProps> = ({ isInDineInMode }) => {
  const { palette, typography, spacing } = useTheme();
  const [t] = useTranslation('intake');

  const { activeDeliveryType, isEatIn, selectedOrderCustomer } = useAppSelector(({ intake }) => intake);
  const { featureFlags } = useAppSelector(({ config }) => config);
  const { selectedStore } = useAppSelector(({ stores }) => stores);
  const extendedDeliveryTypes = useAppSelector(getExtendedAvailableDeliveryTypes);

  const dispatch = useAppDispatch();

  function deliveryTypeChange(_event: React.MouseEvent, value: ExtendedDeliveryType): void {
    if (value) {
      dispatch(setIsEatIn(value.isEatIn));
      dispatch(changeDeliveryType(value.deliveryType));

      if (featureFlags.OfflineModule_ShowRewardsOnPos) {
        dispatch(
          getAllRewards({
            customerId: selectedOrderCustomer?.profile?.id ?? 0,
            pickupType: value.deliveryType,
            storeId: selectedStore?.id ?? 0,
          }),
        );
      }
    }
  }

  function isActiveDeliveryType(value: ExtendedDeliveryType) {
    if (activeDeliveryType !== value.deliveryType) {
      return false;
    }
    return value.isEatIn === undefined || value.isEatIn === isEatIn;
  }

  function getActiveDeliveryType() {
    return extendedDeliveryTypes.find((adt) => isActiveDeliveryType(adt));
  }

  return (
    <>
      {isInDineInMode ? (
        <Box sx={{ p: 1 }}>
          <ToggleButtonGroup
            value={activeDeliveryType}
            size="small"
            sx={{ width: '100%' }}
            exclusive
            onChange={deliveryTypeChange}
          >
            <ToggleButton
              value={PickUpTypesValues.dineIn}
              sx={{
                padding: 2,
                borderRadius: `4px !important`,
                border: '0 !important',
                fill: palette.grey[600],
                color: palette.black.main,
                background: palette.grey[100],
                ...typography.button,
                '&.Mui-selected': {
                  width: '100%',
                  color: palette.black.main,
                  fill: palette.grey[800],
                  border: `1px solid ${palette.grey[300]} !important`,
                },
              }}
              data-testid="receipt-footer__button--order-type-dine-in"
            >
              <DineInIcon />
              <Box sx={{ marginLeft: spacing(1) }}>{t('Dine in')}</Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      ) : (
        <Box sx={{ p: 1 }}>
          <ToggleButtonGroup
            value={getActiveDeliveryType()}
            size="small"
            sx={{ width: '100%', gap: 1 }}
            exclusive
            onChange={deliveryTypeChange}
          >
            {extendedDeliveryTypes.map((edt) => {
              return (
                <ToggleButton
                  key={`${edt.key}`}
                  value={edt}
                  fullWidth
                  sx={{
                    padding: 2,
                    borderRadius: `4px !important`,
                    border: '0 !important',
                    fill: palette.grey[600],
                    color: palette.black.main,
                    background: `${palette.grey[100]} !important`,
                    ...typography.button,
                    '&.Mui-selected': {
                      width: '100%',
                      color: palette.black.main,
                      fill: palette.grey[800],
                      border: `1px solid ${palette.grey[300]} !important`,
                    },
                  }}
                  data-testid={`receipt-footer__button--order-type-${edt.key}`}
                >
                  <edt.icon style={{ maxHeight: spacing(2) }} />
                  {getActiveDeliveryType() === edt && <Box sx={{ marginLeft: spacing(1) }}>{t(edt.key)}</Box>}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Box>
      )}
    </>
  );
};

export default ReceiptToggleGroup;
