import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { LoyaltyTier } from 'typings/Loyalty';
import { useTranslation } from 'react-i18next';

interface LoyaltyTierSeparatorProps {
  tier: LoyaltyTier;
}

const LoyaltyTierSeparator: React.FC<LoyaltyTierSeparatorProps> = ({ tier }) => {
  const { palette, spacing } = useTheme();
  const [t] = useTranslation('intake');

  return (
    <Typography
      sx={{
        width: '100%',
        backgroundColor: palette.customGray.main,
        borderRadius: `${spacing(0.5)}`,
        gap: '10px',
        padding: `${spacing(0.5)} ${spacing(0.5)} ${spacing(0.5)} ${spacing(2)}`,
        display: 'flex',
      }}
      variant="subtitle1"
    >
      {tier.name}
      {tier.numberOfRewardsToShow === 0 && (
        <Typography
          variant="caption"
          sx={{
            backgroundColor: '#DCDDFF',
            color: '#00418E',
            padding: `${spacing(0.5)} ${spacing(1)} ${spacing(0.5)} ${spacing(1)}`,
            gap: `${spacing(0.5)}`,
            borderRadius: `${spacing(0.5)}`,
          }}
        >
          {t('No available rewards')}
        </Typography>
      )}
    </Typography>
  );
};

export default LoyaltyTierSeparator;
