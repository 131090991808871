import React from 'react';
import { Box } from '@mui/system';
import { RewardCardViewItem } from 'typings/Loyalty';
import RewardCard from 'components/Intake/Loyalty/RewardCard';
import LoyaltyTierSeparator from 'components/Intake/Loyalty/LoyaltyTierSeparator';
import { OverflowableRewardsCatalogProps } from './PagedRewardsCatalog';
import buildClasses from './RewardsCatalog.css';

const ScrollableRewardsCatalog: React.FC<OverflowableRewardsCatalogProps> = ({ rewardsCardItems }) => {
  const { classes } = buildClasses();

  function getViewItems(): RewardCardViewItem[] {
    const viewItems: RewardCardViewItem[] = [];
    const tierIds: string[] = [];

    rewardsCardItems.forEach((item) => {
      if (!tierIds.includes(item.tier.id)) {
        tierIds.push(item.tier.id);

        viewItems.push({
          ...item,
          displayTierSeparator: true,
        } as RewardCardViewItem);
      }

      if (item.showReward) {
        viewItems.push({
          ...item,
          displayTierSeparator: false,
        } as RewardCardViewItem);
      }
    });

    return viewItems;
  }

  const rewardViewItems = getViewItems();

  return (
    <Box sx={{ flex: 1, overflowY: 'auto', mt: 3 }}>
      <div className={classes.scrollableWrapper}>
        {rewardViewItems.map((item) => {
          if (item.displayTierSeparator) {
            return <LoyaltyTierSeparator key={`tier-separator-${item.tier.id}`} tier={item.tier} />;
          }

          return <RewardCard reward={item.reward} key={`reward-card-${item.reward.id}`} />;
        })}
      </div>
    </Box>
  );
};

export default ScrollableRewardsCatalog;
