import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LoyaltyPoints } from 'assets/icons';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';

interface LoyaltyRewardPriceProps {
  priceInPoints: number;
  priceInMoney?: number;
}

const LoyaltyRewardPrice: React.FC<LoyaltyRewardPriceProps> = ({ priceInPoints, priceInMoney }) => {
  const { palette, spacing } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'nowrap',
        width: '100%',
        height: '24px !important',
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: palette.grey[800],
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          fontWeight: '500',
          gap: `${spacing(0.5)}`,
        }}
      >
        <Box
          sx={{
            width: '24px !important',
            height: '24px !important',
          }}
        >
          <LoyaltyPoints />
        </Box>
        {`${priceInPoints}`}
        {priceInMoney && ` + ${formatToDisplay(priceInMoney)}`}
      </Typography>
    </Box>
  );
};

export default LoyaltyRewardPrice;
