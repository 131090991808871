/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import ProductCard from 'components/Intake/Product/Card/ProductCard';
import { useAppSelector } from 'hooks/useRedux';
import { Product, Option } from 'typings/Products';
import { Box, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Mousewheel } from 'swiper';
import { useWindowSize } from 'hooks/useWindow';
import SwiperPagination from 'components/Shared/Pagination/Pagination';
import 'swiper/swiper.min.css';
import buildClasses from './ProductsCatalog.css';

type ProductCatalogProps = {
  products: Product[];
  onProductClick: (product: Product) => void;
};

export interface ProductPagination {
  activePage: number;
  pageProducts: Product[][];
}

const PagedCatalog: React.FC<ProductCatalogProps> = ({ products, onProductClick }) => {
  const { classes } = buildClasses();
  const productGridRef = useRef<HTMLElement>(null);
  const { sizing, breakpoints } = useTheme();
  const { options, activeOptionId } = useAppSelector((store) => store.products);
  const { selectedStore } = useAppSelector((state) => state.stores);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const [pagination, setProductPagination] = useState<ProductPagination>({
    activePage: 0,
    pageProducts: [[]],
  });
  const { width, height } = useWindowSize();

  const calculatePageCount = (): { productsPerPage: number; pagesCount: number } => {
    const gridHeight = productGridRef.current?.clientHeight ?? 0;
    const productCartHeight = sizing.productCard.height;
    const productsPerColumn = Math.floor(
      (gridHeight - sizing.spacingBase * 5) / (productCartHeight + sizing.spacingBase * 1),
    );
    const productsPerRow = getProductRowAmount(width);
    if (productsPerColumn === 0) {
      return { pagesCount: Math.ceil(products.length / productsPerRow), productsPerPage: productsPerRow };
    }

    const productsPerPage = productsPerColumn * productsPerRow;
    const pagesCount = Math.ceil(products.length / productsPerPage);
    return { pagesCount, productsPerPage };
  };

  const getProductRowAmount = (windowWidth: number | undefined): number => {
    if (windowWidth && windowWidth < breakpoints.values.md) return sizing.productCard.lineCount.xs;
    if (windowWidth && windowWidth > breakpoints.values.xl) return sizing.productCard.lineCount.xl;
    return sizing.productCard.lineCount.md;
  };

  const recalculatePagination = () => {
    const { pagesCount, productsPerPage } = calculatePageCount();
    const productsPages = [];
    const sortedProducts = products.sort((a, b) => a.sortOrder - b.sortOrder);
    for (let i = 0; i < pagesCount; i += 1) {
      productsPages.push(sortedProducts.slice(i * productsPerPage, (i + 1) * productsPerPage));
    }
    setProductPagination({
      activePage: 0,
      pageProducts: productsPages,
    });
  };

  useEffect(() => {
    const optionFound = options.find((opt) => opt.id === activeOptionId);
    setSelectedOption(optionFound);
  }, [activeOptionId]);

  useEffect(() => {
    recalculatePagination();
  }, [width, height]);

  useEffect(() => {
    recalculatePagination();
  }, [products]);

  return (
    <Box sx={{ mt: 3, flex: 1, overflow: 'hidden' }} ref={productGridRef}>
      <Swiper
        modules={[Pagination, Mousewheel]}
        className={classes.swiper}
        mousewheel
        wrapperClass={classes.swiperWrapper}
        onSlideChange={(swiper) => {
          setProductPagination({
            ...pagination,
            activePage: swiper.activeIndex,
          });
        }}
      >
        {pagination.pageProducts.map((page, index) => {
          return (
            <SwiperSlide className={classes.swiperSlide} key={`SWIPER_PAGE_${index}`}>
              {page.map((product) => (
                <ProductCard
                  product={product}
                  selectedOption={selectedOption}
                  onProductSelected={onProductClick}
                  hideOptionName
                  key={`PRODUCT_${product.id}`}
                  displayImage={selectedStore?.displayProductImagesDuringIntake}
                />
              ))}
            </SwiperSlide>
          );
        })}
        <SwiperPagination activePage={pagination.activePage} pageItems={pagination.pageProducts} />
      </Swiper>
    </Box>
  );
};

export default PagedCatalog;
