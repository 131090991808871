import React from 'react';
import { Box } from '@mui/material';
import { RewardTab } from 'typings/Loyalty';
import { useAppSelector } from 'hooks/useRedux';
import PartialLoader from 'components/Shared/Loaders/PartialLoader';
import RewardsView from './RewardsView';

const areRewardsLoaded = (rewardTabs: RewardTab[]): boolean => {
  return rewardTabs.length > 0;
};

const RewardsContainer: React.FC = () => {
  const { rewardTabs } = useAppSelector((store) => store.loyalty);

  if (!areRewardsLoaded(rewardTabs)) return <PartialLoader />;

  return (
    <Box sx={{ overflowY: 'auto', height: '100%' }}>
      <RewardsView />
    </Box>
  );
};

export default RewardsContainer;
