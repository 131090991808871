import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { ReceiptProduct } from 'typings/Basket';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAppSelector } from 'hooks/useRedux';
import { getPaymentFormatter } from 'stores/Payments/payment.selector';
import { useTheme } from '@mui/system';
import ItemQuantity from './ItemQuantity/ItemQuantity';
import buildClass from './ReceiptProduct.css';

interface ReceiptBoundedProductProps {
  boundedProduct: ReceiptProduct;
  changeAutoAddedItemQuantity?: (itemId: number, quantity: number) => void;
  maxQuantity: number;
}

const ReceiptBoundedProduct: React.FC<ReceiptBoundedProductProps> = ({
  changeAutoAddedItemQuantity,
  boundedProduct,
  maxQuantity,
}) => {
  const { classes } = buildClass();
  const { palette } = useTheme();
  const formatToDisplay = useAppSelector(getPaymentFormatter());

  return (
    <Box
      data-testid="receipt-item-bounded__wrapper"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        background: palette.grey[50],
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ItemQuantity quantity={boundedProduct.quantity} />
      <div className={classes.itemPriceDetails}>
        <div className={classes.itemDetails}>
          <Typography
            className={classes.itemName}
            variant="subtitle2"
            data-testid="receipt-item-bounded__label--name"
          >
            {boundedProduct.itemName}
          </Typography>
          <Typography
            className={classes.itemDescription}
            variant="body2"
            data-testid="receipt-item-bounded__label--description"
          >
            {boundedProduct.optionName}
          </Typography>
        </div>
        <div className={classes.priceDetails} data-testid="receipt-item-bounded__label--price">
          <Typography className={classes.itemTotalPrice} variant="subtitle2">
            {formatToDisplay(boundedProduct.itemPrice?.total?.originalGrossValue ?? 0)}
          </Typography>
        </div>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <IconButton
          data-testid="receipt-item-bounded__button--add-quantity"
          disabled={boundedProduct.quantity <= 0}
          onClick={() => {
            changeAutoAddedItemQuantity &&
              changeAutoAddedItemQuantity(boundedProduct.id, boundedProduct.quantity - 1);
          }}
        >
          <RemoveIcon />
        </IconButton>
        <IconButton
          data-testid="receipt-item-bounded__button--subtract-quantity"
          disabled={boundedProduct.quantity >= maxQuantity}
          onClick={() => {
            changeAutoAddedItemQuantity &&
              changeAutoAddedItemQuantity(boundedProduct.id, boundedProduct.quantity + 1);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ReceiptBoundedProduct;
