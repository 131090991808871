import { Box, useTheme } from '@mui/material';
import LoyaltyRewardsFilters from 'components/Intake/Loyalty/LoyaltyFilters';
import { useAppSelector } from 'hooks/useRedux';
import React, { useState } from 'react';
import { getAllTiers } from 'stores/Loyalty/loyalty.selector';
import RewardsCatalog from '../../../components/Intake/Loyalty/RewardsCatalog';

const RewardsView: React.FC = () => {
  const { palette, spacing } = useTheme();
  const { rewardTabs } = useAppSelector((store) => store.loyalty);
  const [selectedTierId, setSelectedTierId] = useState<string>();
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [showOnlyAvailableRewards, setshowOnlyAvailableRewards] = useState<boolean>(false);
  const allTiers = useAppSelector(getAllTiers);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        padding: spacing(4),
        paddingBottom: spacing(2),
        backgroundColor: palette.background.default,
      }}
    >
      <LoyaltyRewardsFilters
        tiers={allTiers}
        activeTierId={selectedTierId}
        showOnlyAvailableRerwards={showOnlyAvailableRewards}
        searchRewardPhrase={searchPhrase}
        onTierChange={setSelectedTierId}
        onShowOnlyAvailableRewardsChanged={setshowOnlyAvailableRewards}
        onSearchPhraseChanged={setSearchPhrase}
      />
      <RewardsCatalog
        rewardTabs={rewardTabs}
        activeTierId={selectedTierId}
        searchPhrase={searchPhrase}
        showOnlyAvailableRewards={showOnlyAvailableRewards}
      />
    </Box>
  );
};

export default RewardsView;
