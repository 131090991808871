import React from 'react';
import { Box, useTheme } from '@mui/material';
import { RewardDefaultImage } from 'assets/icons';
import { Reward } from 'typings/Loyalty';

interface LoyaltyRewardImageProps {
  reward: Reward;
}

const LoyaltyRewardImage: React.FC<LoyaltyRewardImageProps> = ({ reward }) => {
  const { palette, spacing } = useTheme();

  return (
    <Box
      sx={{
        height: '100%',
        width: '64px',
        marginRight: '8px',
      }}
    >
      {reward.imageUrl && (
        <img
          src={reward.imageUrl}
          alt={reward.name}
          style={{
            width: '64px',
            height: '64px',
            objectFit: 'contain',
            borderRadius: `${spacing(1)}`,
          }}
        />
      )}
      {!reward.imageUrl && (
        <Box
          sx={{
            width: '64px',
            height: '64px',
            padding: `${spacing(2)}`,
            backgroundColor: palette.background.default,
            textAlign: 'center',
            borderRadius: `${spacing(1)}`,
            gap: '10px',
          }}
        >
          <RewardDefaultImage />
        </Box>
      )}
    </Box>
  );
};

export default LoyaltyRewardImage;
